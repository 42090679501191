import { WindowSizeTracker } from "./modules/tools.js"

// Masonry Controller
document.addEventListener('DOMContentLoaded', () => {
    const masonryContainer = Array.from(document.querySelectorAll('.sk_masonry_kacheln:not(.block-editor-block-list__block)'))

    masonryContainer.forEach(container => create_masonry_layout(container))
})


function create_masonry_layout(container) {
    // Set the container up
    const columns = parseFloat(container.dataset.columns)
    const columnshift = parseFloat(container.dataset.columnshift)
    const wrapper = container.querySelector('.sk_masonry_container')
    const items = Array.from(wrapper.querySelectorAll('.sk_kachel:not(.disabled)'))
    const masonryColumns = Array.from({ length: columns }, () => [])

    items.forEach((item, index) => {
        const colIndex = index % columns
        masonryColumns[colIndex].push(item)
    })

    console.log(items)

    if (document.body.scrollWidth > 819) {
        wrapper.setAttribute('style', `grid-template-columns:repeat(${columns},1fr);`)
        
        wrapper.innerHTML = '';

        masonryColumns.forEach((column, index) => {
            let div = document.createElement('div')
            if (columnshift != 0 && (index + 1) === columnshift) {
                div.classList.add('sk_masonry_column', 'sk_masonry_shift')
            } else {
                div.classList.add('sk_masonry_column')
            }
            column.forEach(item => div.appendChild(item))
            wrapper.appendChild(div)
        })

        container.classList.add('masonry-loaded')
    } else {
        wrapper.innerHTML = '';
        wrapper.setAttribute('style', `grid-template-columns:1fr;`)
        items.forEach(item => {
            wrapper.appendChild(item)
        })
        container.classList.add('masonry-loaded')
    }
    
    const masonryWindowTracker = new WindowSizeTracker(({ width, height, isWidthChange, isHeightChange }) => {
        if (document.body.scrollWidth > 819) {
            wrapper.setAttribute('style', `grid-template-columns:repeat(${columns},1fr);`)
            
            wrapper.innerHTML = '';
    
            masonryColumns.forEach((column, index) => {
                let div = document.createElement('div')
                if (columnshift != 0 && (index + 1) === columnshift) {
                    div.classList.add('sk_masonry_column', 'sk_masonry_shift')
                } else {
                    div.classList.add('sk_masonry_column')
                }
                column.forEach(item => div.appendChild(item))
                wrapper.appendChild(div)
            })
    
            container.classList.add('masonry-loaded')
        } else {
            wrapper.innerHTML = '';
            wrapper.setAttribute('style', `grid-template-columns:1fr;`)
            items.forEach(item => {
                wrapper.appendChild(item)
            })
            container.classList.add('masonry-loaded')
        }
    })
    
}